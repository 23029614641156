import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "article"
};
import { ref } from "vue";
import contentText from "./contentText.vue";
import contentTitle from "./contentTitle.vue";
export default {
  __name: 'mainContent',
  props: {
    detail: {
      type: Object,
      default: () => {}
    }
  },
  setup(__props) {
    const title = ref("生前简介");
    const imgTitle = ref("生前简介");
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(contentTitle, {
        title: title.value
      }, null, 8, ["title"]), _createVNode(contentText, {
        content: __props.detail.details,
        imgTitle: imgTitle.value
      }, null, 8, ["content", "imgTitle"])]);
    };
  }
};