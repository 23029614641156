import axios from "axios";
import api from "@/api/api";
// create an axios instance
const service = axios.create({
  baseURL: api.baseURL,
  // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 100000 // request timeout
});

// request interceptor
service.interceptors.request.use(config => {
  // do something before request is sent

  // if (store.getters.token) {
  //   // let each request carry token
  //   // ['X-Token'] is a custom headers key
  //   // please modify it according to the actual situation

  //   // 这里是因为后端，不把token值存在headers里，所以还得自己判断下是否是undefined
  //   if (config.params) {
  //     config.params.adminSessionId = getToken()
  //   } else if (config.data) {
  //     config.data.adminSessionId = getToken()
  //   }
  // }
  return config;
}, error => {
  // do something with request error // for debug
  return Promise.reject(error);
});

// response interceptor
service.interceptors.response.use(
/**
 * If you want to get http information such as headers or status
 * Please return  response => response
 */

/**
 * Determine the request status by custom code
 * Here is just an example
 * You can also judge the status by HTTP Status Code
 */
response => {
  const res = response.data;

  // 存在接口token失效，接口报错的时候，就跳到login页面
  if (res.status === 101) {
    // ElMessage({
    //   message: res.error || 'Error',
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    // token失效了，就清空token和用户信息，然后返回login页面
    // store.dispatch('user/resetToken').then(() => {
    //   router.push('/login')
    // })
  }
  if (res.status !== 200) {
    ElMessage({
      message: res.message || "Error",
      type: "error",
      duration: 5 * 1000
    });
  }
  return res;
  // }
}, error => {
  console.log("err" + error); // for debug
  ElMessage({
    message: error.message,
    type: "error",
    duration: 5 * 1000
  });
  return Promise.reject(error);
});
export default service;