

import { createRouter, createWebHashHistory } from 'vue-router'
import index from "@/pages/sacrificeIndex";
import login from '@/pages/loginPage'
import oblation from "@/pages/oblationPage"
import fireWork from "@/pages/fireWorkPage"
import lamp from "@/pages/lampPage"
import perfumed from "@/pages/perfumedPage"
import banger from "@/pages/bangerPage"
import search from '@/pages/searchPage'
const routes = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/login',
    component: login,
    name: 'login'
  },
  {
    path: '/search',
    component: search,
    name: 'search'
  },
  {
    path: '/index',
    component: index,
    name: 'index',
    children: [
      {
        path: '/oblation', component: oblation, name: 'oblation'
      },
      {
        path: '/fireWork', component: fireWork, name: 'firework'
      },
      {
        path: '/lamp', component: lamp, name: 'lamp'
      },
      {
        path: '/perfumed', component: perfumed, name: 'perfumed'
      },
      {
        path: '/banger', component: banger, name: 'banger'
      },
    ]
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router;
