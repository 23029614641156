import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { ref, onMounted, computed, watch } from "vue";
import commonList from "@/components/commonList.vue";
import { useListStore } from "../store";
export default {
  __name: 'perfumedPage',
  setup(__props) {
    const store = useListStore();
    let imgList = computed(() => store.$state.imgList);
    const perfumedList = ref([]);
    watch(() => imgList, () => {
      let newList = [];
      imgList.value.forEach(item => {
        if (item.name === "供香") {
          newList = item.sacrificeAmountList;
        }
      });
      perfumedList.value = newList;
    }, {
      immediate: true,
      deep: true
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", null, [_createVNode(commonList, {
        imgList: perfumedList.value
      }, null, 8, ["imgList"])]);
    };
  }
};