import { createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "rightArea"
};
import { nextTick, onMounted, ref } from "vue";
import contentTitle from "@/components/contentTitle.vue";
import contentText from "@/components/contentText.vue";
import rightTab from "@/components/rightTab.vue";
export default {
  __name: 'rightArea',
  setup(__props) {
    let defaultList = ref([]);
    onMounted(() => {
      setTimeout(() => {
        defaultList.value = JSON.parse(localStorage.getItem("imgList"));
        console.log(defaultList.value, '取值');
      }, 1000);
    });
    const title = ref("进献祭品");
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(contentTitle, {
        title: title.value
      }, null, 8, ["title"]), _createVNode(contentText, {
        defaultList: _unref(defaultList)
      }, null, 8, ["defaultList"]), _createVNode(rightTab)]);
    };
  }
};