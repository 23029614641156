import "core-js/modules/es.array.push.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, vShow as _vShow, withDirectives as _withDirectives } from "vue";
import _imports_0 from '../assets/images/firework.gif';
import _imports_1 from '../assets/images/shine.png';
import _imports_2 from '../assets/images/fireworkMusic.mp3';
import _imports_3 from '../assets/images/banger.mp3';
const _hoisted_1 = ["onClick"];
const _hoisted_2 = ["src"];
const _hoisted_3 = ["src"];
const _hoisted_4 = {
  src: _imports_0,
  alt: "",
  class: "fireworkGif"
};
import { ref, onMounted, computed, nextTick } from "vue";
import { useRouter } from "vue-router";
import { useListStore } from "../store";
export default {
  __name: 'commonList',
  props: {
    imgList: {
      type: Array
    },
    defaultList: {
      type: Array
    }
  },
  setup(__props) {
    const store = useListStore();
    const props = __props;
    const activeIndex = computed(() => store.$state.activeIndex);
    const fireworkVisible = ref(false);
    //鞭炮图片显示
    const bangerVisible = ref(false);
    //当前点击下标
    let activityIndex = ref(0);
    const router = useRouter();
    //尺寸宽度
    const sizeWidth = ref("");
    const clickBtn = ref("");
    //尺寸高度
    const sizeHeight = ref("");
    const imgVisible = ref(false);
    const leftShine = ref("");
    const rightShine = ref("");
    const middleShine = ref("");
    const fireworkMusic = ref("");
    const backgroundUrl = ref("");
    const bulletText = ref("");
    const commonImg = ref("");
    const bangerMusic = ref("");
    const counter = ref(0);
    onMounted(() => {
      nextTick().then(() => {
        changeSize();
      });
    });
    const changeSize = () => {
      switch (router.currentRoute.value.path) {
        case "/firework":
        case "/banger":
        case "/perfumed":
          backgroundUrl.value = require("@/assets/images/bigBorder.png");
          sizeWidth.value = "284px";
          sizeHeight.value = "142px";
          break;
        case "/lamp":
        case "/oblation":
        case "/index":
          backgroundUrl.value = require("@/assets/images/itemBorder.png");
          sizeWidth.value = "141px";
          sizeHeight.value = "142px";
          imgVisible.value = true;
          break;
      }
    };
    const rectStyle = computed({
      get() {
        return {
          "--display": bangerVisible.value ? "block" : "none"
        };
      }
    });
    //点击不同页面变色
    const changeColor = index => {
      activityIndex.value = index;
    };
    const imgRefs = ref([]);
    const setImageRef = index => {
      return el => {
        imgRefs.value[index] = el;
      };
    };
    const runningAnimation = middle => {
      if (middle) {
        middleShine.value.addEventListener("animationend", () => {
          middleShine.value.classList.remove("shineAnimation");
        });
        if (!middleShine.value.classList.contains("shineAnimation")) {
          middleShine.value.classList.add("shineAnimation");
        }
      } else {
        leftShine.value.addEventListener("animationend", () => {
          leftShine.value.classList.remove("shineAnimation");
        });
        rightShine.value.addEventListener("animationend", () => {
          rightShine.value.classList.remove("shineAnimation");
        });
        if (!leftShine.value.classList.contains("shineAnimation") && !rightShine.value.classList.contains("shineAnimation")) {
          leftShine.value.classList.add("shineAnimation");
          rightShine.value.classList.add("shineAnimation");
        }
      }
    };
    //根据不同路由点击献上祭品
    const Offering = () => {
      let timing = `${activityIndex.value + 1}0000`;
      let timer = 0;
      counter.value++;
      switch (router.currentRoute.value.path) {
        case "/oblation":
        case "/index":
          imgRefs.value[activityIndex.value].addEventListener("animationend", () => {
            imgRefs.value[activityIndex.value].classList.remove(`animationName${activityIndex.value}`);
            clickBtn.value.disabled = false;
          });
          if (!imgRefs.value[activityIndex.value].classList.contains(`animationName${activityIndex.value}`)) {
            console.log(`animationName${activityIndex.value}`);
            imgRefs.value[activityIndex.value].classList.add(`animationName${activityIndex.value}`);
            clickBtn.value.disabled = true;
          }
          break;
        case "/firework":
          clearTimeout(timer);
          fireworkVisible.value = true;
          fireworkMusic.value.play();
          timer = setTimeout(() => {
            fireworkMusic.value.pause();
            fireworkVisible.value = false;
          }, timing);
          break;
        case "/banger":
          clearTimeout(timer);
          bangerVisible.value = true;
          bangerMusic.value.play();
          timer = setTimeout(() => {
            bangerMusic.value.pause();
            bangerVisible.value = false;
          }, timing);
          break;
        case "/lamp":
          clearTimeout(timer);
          runningAnimation();
          store.lampImg = imgRefs.value[activityIndex.value].src;
          break;
        case "/perfumed":
          clearTimeout(timer);
          runningAnimation("middle");
          store.perfumedImg = imgRefs.value[activityIndex.value].src;
          break;
      }
      store.bulletList.push({
        img: commonImg.value[activityIndex.value].src,
        text: bulletText.value[activityIndex.value].innerHTML
      });
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", null, [_createElementVNode("ul", {
        class: "oblationList",
        style: _normalizeStyle(rectStyle.value)
      }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.defaultList ? __props.defaultList : __props.imgList, (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          key: index,
          class: _normalizeClass({
            selected: _unref(activityIndex) == index,
            selected1: _unref(activityIndex) == index && (activeIndex.value == 2 || activeIndex.value == 3 || activeIndex.value == 4)
          }),
          onClick: $event => changeColor(index),
          style: _normalizeStyle({
            width: sizeWidth.value,
            height: sizeHeight.value,
            background: `url(${backgroundUrl.value})`
          })
        }, [_createVNode(_Transition, {
          name: "fade"
        }, {
          default: _withCtx(() => [_createElementVNode("img", {
            src: item.endImgUrl,
            alt: "",
            ref_for: true,
            ref: setImageRef(index),
            class: "hiddenImg"
          }, null, 8, _hoisted_2)]),
          _: 2
        }, 1024), _createElementVNode("img", {
          src: item.imgUrl,
          alt: "",
          class: "commonImg",
          ref_for: true,
          ref_key: "commonImg",
          ref: commonImg
        }, null, 8, _hoisted_3), _createElementVNode("p", {
          ref_for: true,
          ref_key: "bulletText",
          ref: bulletText
        }, _toDisplayString(item.name), 513)], 14, _hoisted_1);
      }), 128))], 4), _createElementVNode("button", {
        class: "clickBg",
        onClick: Offering,
        ref_key: "clickBtn",
        ref: clickBtn
      }, "献上祭品", 512), _withDirectives(_createElementVNode("img", _hoisted_4, null, 512), [[_vShow, fireworkVisible.value]]), _createElementVNode("img", {
        src: _imports_1,
        alt: "",
        class: "leftShine",
        ref_key: "leftShine",
        ref: leftShine
      }, null, 512), _createElementVNode("img", {
        src: _imports_1,
        alt: "",
        class: "middleShine",
        ref_key: "middleShine",
        ref: middleShine
      }, null, 512), _createElementVNode("img", {
        src: _imports_1,
        alt: "",
        class: "rightShine",
        ref_key: "rightShine",
        ref: rightShine
      }, null, 512), _createElementVNode("audio", {
        ref_key: "fireworkMusic",
        ref: fireworkMusic
      }, _cache[0] || (_cache[0] = [_createElementVNode("source", {
        src: _imports_2
      }, null, -1)]), 512), _createElementVNode("audio", {
        ref_key: "bangerMusic",
        ref: bangerMusic
      }, _cache[1] || (_cache[1] = [_createElementVNode("source", {
        src: _imports_3
      }, null, -1)]), 512)]);
    };
  }
};