import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "fireworkWrap"
};
import { ref, onMounted, computed, watch } from "vue";
import commonList from "@/components/commonList.vue";
import { useListStore } from "../store";
export default {
  __name: 'fireWorkPage',
  setup(__props) {
    const store = useListStore();
    let imgList = computed(() => store.$state.imgList);
    const fireWorkList = ref([]);
    //点击按钮烟火的显示和隐藏
    // const rectStyle = computed({
    //   get() {
    //     return {
    //       "--display": showFireWork.value ? "block" : "none",
    //     };
    //   },
    // });
    watch(() => imgList, () => {
      let newList = [];
      imgList.value.forEach(item => {
        if (item.name === "烟花") {
          newList = item.sacrificeAmountList;
        }
      });
      fireWorkList.value = newList;
    }, {
      immediate: true,
      deep: true
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(commonList, {
        imgList: fireWorkList.value
      }, null, 8, ["imgList"])]);
    };
  }
};