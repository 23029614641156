import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "contentText"
};
const _hoisted_2 = ["innerHTML"];
import { useRoute } from "vue-router";
export default {
  __name: 'contentText',
  props: {
    content: String,
    imgTitle: String,
    defaultList: Array
  },
  setup(__props) {
    const route = useRoute();
    return (_ctx, _cache) => {
      const _component_common_list = _resolveComponent("common-list");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [__props.content ? (_openBlock(), _createElementBlock("p", {
        key: 0,
        innerHTML: __props.content,
        class: "contentIntroduce"
      }, null, 8, _hoisted_2)) : _createCommentVNode("", true), __props.defaultList && _unref(route).path.includes('index') ? (_openBlock(), _createBlock(_component_common_list, {
        key: 1,
        class: "commonList",
        defaultList: __props.defaultList
      }, null, 8, ["defaultList"])) : _createCommentVNode("", true)]);
    };
  }
};