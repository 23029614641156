import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '../assets/images/frame.png';
const _hoisted_1 = {
  class: "imgBg"
};
const _hoisted_2 = {
  class: "imgContent"
};
const _hoisted_3 = ["src"];
export default {
  __name: 'imgArea',
  props: {
    imgList: {
      type: String,
      default: ""
    }
  },
  setup(__props) {
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_cache[1] || (_cache[1] = _createElementVNode("div", {
        class: "imgTitle"
      }, [_createElementVNode("p", null, "中慧展厅")], -1)), _createElementVNode("div", _hoisted_2, [_cache[0] || (_cache[0] = _createElementVNode("img", {
        src: _imports_0,
        alt: ""
      }, null, -1)), _createElementVNode("img", {
        src: __props.imgList,
        alt: "",
        class: "plaqueImg"
      }, null, 8, _hoisted_3)])]);
    };
  }
};