import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "tabList"
};
const _hoisted_2 = ["onClick"];
import { ref, computed, onMounted, watch, onUpdated } from "vue";
import { useRouter } from "vue-router";
import { useListStore } from "../store";
export default {
  __name: 'rightTab',
  setup(__props) {
    const store = useListStore();
    let imgList = computed(() => store.$state.imgList);
    const router = useRouter();
    const activityIndex = ref(0);
    const localIndex = ref();
    onMounted(() => {
      activityIndex.value = localStorage.getItem("activityIndex");
    });
    const clickChange = (tab, index) => {
      activityIndex.value = index;
      store.$state.activeIndex = activityIndex.value;
      localStorage.setItem("activityIndex", activityIndex.value);
      switch (tab) {
        case "祭品":
          router.push("/oblation");
          break;
        case "供灯":
          router.push("/lamp");
          break;
        case "供香":
          router.push("/perfumed");
          break;
        case "烟花":
          router.push("/firework");
          break;
        case "鞭炮":
          router.push("/banger");
          break;
      }
    };
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_router_view, {
        class: "routerView"
      }), _createElementVNode("ul", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(imgList), (tab, index) => {
        return _openBlock(), _createElementBlock("li", {
          key: index,
          onClick: $event => clickChange(tab.name, index)
        }, [_createElementVNode("a", {
          href: "javascript:;",
          class: _normalizeClass({
            selected: activityIndex.value == index
          })
        }, _toDisplayString(tab.name), 3)], 8, _hoisted_2);
      }), 128))])]);
    };
  }
};