import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue";
import _imports_0 from '../assets/images/searchIcon.png';
import _imports_1 from '../assets/images/frame.png';
import _imports_2 from '../assets/images/null.png';
const _hoisted_1 = {
  class: "searchWrapper"
};
const _hoisted_2 = {
  class: "searchBox"
};
const _hoisted_3 = {
  key: 0,
  class: "searchList"
};
const _hoisted_4 = ["onClick"];
const _hoisted_5 = {
  class: "imgArea"
};
const _hoisted_6 = ["src"];
const _hoisted_7 = {
  class: "imgText"
};
const _hoisted_8 = {
  class: "plaquesNo"
};
const _hoisted_9 = {
  class: "plaquesList"
};
const _hoisted_10 = {
  class: "plaquesName"
};
const _hoisted_11 = {
  key: 1,
  class: "nullPage"
};
import { getSearchData } from "@/api/search";
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useListStore } from "../store";
export default {
  __name: 'searchPage',
  setup(__props) {
    const store = useListStore();
    const keyword = ref("");
    const router = useRouter();
    const searchParams = ref({
      keyword: "",
      deviceId: "",
      tenantId: ""
    });
    const searchList = ref([]);
    const getSearch = () => {
      searchParams.value.keyword = keyword.value;
      searchParams.value.deviceId = localStorage.getItem("deviceId");
      searchParams.value.tenantId = localStorage.getItem("tenantId");
      getSearchData(searchParams.value).then(res => {
        searchList.value = res.data;
      });
    };
    const jumpRoute = number => {
      store.$state.acupointNo = number;
      localStorage.setItem("acupointNo", store.$state.acupointNo);
      router.push("/index");
    };
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_input, {
        class: "searchInput",
        placeholder: "请输入(亡故亲人/穴位编号/联系人方式/联系人)",
        modelValue: keyword.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => keyword.value = $event)
      }, null, 8, ["modelValue"]), _cache[2] || (_cache[2] = _createElementVNode("img", {
        src: _imports_0,
        alt: "",
        class: "searchIcon"
      }, null, -1)), _createElementVNode("div", {
        class: "rightText"
      }, [_cache[1] || (_cache[1] = _createElementVNode("div", {
        class: "rightBorder"
      }, null, -1)), _createElementVNode("p", {
        onClick: getSearch,
        class: "searchText"
      }, "搜索")])]), _createElementVNode("div", {
        class: _normalizeClass(["searchMain", searchList.value.length ? '' : 'center'])
      }, [searchList.value.length ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(searchList.value, (searchItem, searchIndex) => {
        return _openBlock(), _createElementBlock("li", {
          key: searchItem.id
        }, [_createElementVNode("a", {
          href: "#",
          onClick: $event => jumpRoute(searchItem.acupointNo)
        }, [_createElementVNode("div", _hoisted_5, [_cache[3] || (_cache[3] = _createElementVNode("img", {
          src: _imports_1,
          alt: "",
          class: "frameImg"
        }, null, -1)), _createElementVNode("img", {
          src: searchItem.plaquesImg,
          alt: "",
          class: "plaquesImg"
        }, null, 8, _hoisted_6)]), _createElementVNode("div", _hoisted_7, [_createElementVNode("p", _hoisted_8, "编号:" + _toDisplayString(searchItem.acupointNo), 1), _createElementVNode("p", _hoisted_9, "6排" + _toDisplayString(searchIndex + 5) + "列", 1), _cache[4] || (_cache[4] = _createElementVNode("div", {
          class: "border"
        }, null, -1)), _createElementVNode("p", _hoisted_10, _toDisplayString(searchItem.plaquesName), 1)])], 8, _hoisted_4)]);
      }), 128))])) : (_openBlock(), _createElementBlock("div", _hoisted_11, _cache[5] || (_cache[5] = [_createElementVNode("img", {
        src: _imports_2,
        alt: ""
      }, null, -1), _createElementVNode("p", null, "未查到您的穴位", -1)])))], 2)]);
    };
  }
};