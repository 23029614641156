import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue";
import _imports_0 from '../assets/images/desk.png';
import _imports_1 from '../assets/images/flower.png';
import _imports_2 from '../assets/images/base.png';
import _imports_3 from '../assets/images/apple.png';
import _imports_4 from '../assets/images/plaques.png';
import _imports_5 from '../assets/images/stove.png';
import _imports_6 from '../assets/images/banana.png';
const _hoisted_1 = {
  class: "wrapper"
};
const _hoisted_2 = {
  class: "main"
};
const _hoisted_3 = {
  class: "footer"
};
const _hoisted_4 = {
  class: "imgList"
};
const _hoisted_5 = ["src"];
const _hoisted_6 = ["src"];
const _hoisted_7 = ["src"];
const _hoisted_8 = {
  class: "bulletChat"
};
const _hoisted_9 = {
  class: "bulletList"
};
const _hoisted_10 = ["src"];
import { ref, onMounted, computed, nextTick, onActivated } from "vue";
import imgArea from "@/components/imgArea";
import mainContent from "@/components/mainContent";
import rightArea from "@/components/rightArea";
import { getIndexData } from "@/api/index";
import { useListStore } from "../store";
export default {
  __name: 'sacrificeIndex',
  setup(__props) {
    const store = useListStore();
    const plaques = ref("");
    //生前照片
    const peopleImg = ref("");
    //生前人物名字
    const peopleName = ref("");
    //生前简介
    const detail = ref({});
    const lampImg = computed(() => store.$state.lampImg);
    const perfumedImg = computed(() => store.$state.perfumedImg);
    //弹幕列表
    const defaultList = computed(() => store.$state.imgList[0].sacrificeAmountList);
    const bulletList = computed(() => store.$state.bulletList);
    const queryParams = {
      deviceSn: "",
      acupointNo: ""
    };
    onMounted(() => {
      getData();
    });
    const setFontSize = () => {
      nextTick(() => {
        switch (plaques.value.textContent.length) {
          case 7:
            plaques.value.style.fontSize = "30px";
            break;
          case 6:
            plaques.value.style.fontSize = "34px";
            break;
          case 5:
            plaques.value.style.fontSize = "39px";
            break;
          case 4:
            plaques.value.style.bottom = "24%";
            break;
        }
      });
    };
    //获取首页数据
    const getData = () => {
      queryParams.deviceSn = localStorage.getItem("deviceSn");
      queryParams.acupointNo = localStorage.getItem("acupointNo");
      getIndexData(queryParams).then(res => {
        const sacrifice = res.data?.sacrifice;
        store.$state.imgList = sacrifice?.sacrificeAmountTypeList;
        localStorage.setItem("imgList", JSON.stringify(defaultList.value));
        sacrifice?.sacrificePlaquesList.forEach(item => {
          peopleImg.value = item.plaquesImg;
          peopleName.value = item.plaquesName;
          detail.value.details = item.details;
        });
        setFontSize();
      });
    };
    return (_ctx, _cache) => {
      const _component_el_scrollbar = _resolveComponent("el-scrollbar");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_unref(mainContent), {
        detail: detail.value
      }, null, 8, ["detail"]), _createVNode(_unref(imgArea), {
        imgList: peopleImg.value
      }, null, 8, ["imgList"]), _createVNode(_unref(rightArea))]), _createElementVNode("div", _hoisted_3, [_cache[8] || (_cache[8] = _createElementVNode("img", {
        src: _imports_0,
        alt: "",
        class: "desk"
      }, null, -1)), _createElementVNode("ul", _hoisted_4, [_cache[4] || (_cache[4] = _createElementVNode("li", null, [_createElementVNode("img", {
        src: _imports_1,
        alt: ""
      })], -1)), _createElementVNode("li", null, [_createElementVNode("img", {
        src: lampImg.value ? lampImg.value : require('@/assets/images/candle.gif'),
        alt: ""
      }, null, 8, _hoisted_5), _cache[0] || (_cache[0] = _createElementVNode("img", {
        src: _imports_2,
        alt: ""
      }, null, -1))]), _cache[5] || (_cache[5] = _createElementVNode("li", null, [_createElementVNode("img", {
        src: _imports_3,
        alt: ""
      })], -1)), _createElementVNode("li", null, [_cache[1] || (_cache[1] = _createElementVNode("img", {
        src: _imports_4,
        alt: ""
      }, null, -1)), _createElementVNode("p", {
        class: "plaquesText",
        ref_key: "plaques",
        ref: plaques
      }, _toDisplayString(peopleName.value), 513)]), _createElementVNode("li", null, [_createElementVNode("img", {
        src: perfumedImg.value ? perfumedImg.value : require('@/assets/images/fragrant.gif'),
        alt: "",
        class: "fragrantGif"
      }, null, 8, _hoisted_6), _cache[2] || (_cache[2] = _createElementVNode("img", {
        src: _imports_5,
        alt: ""
      }, null, -1))]), _createElementVNode("li", null, [_createElementVNode("img", {
        src: lampImg.value ? lampImg.value : require('@/assets/images/candle.gif'),
        alt: ""
      }, null, 8, _hoisted_7), _cache[3] || (_cache[3] = _createElementVNode("img", {
        src: _imports_2,
        alt: ""
      }, null, -1))]), _cache[6] || (_cache[6] = _createElementVNode("li", null, [_createElementVNode("img", {
        src: _imports_6,
        alt: ""
      })], -1)), _cache[7] || (_cache[7] = _createElementVNode("li", null, [_createElementVNode("img", {
        src: _imports_1,
        alt: ""
      })], -1))])]), _createElementVNode("div", _hoisted_8, [_createVNode(_component_el_scrollbar, null, {
        default: _withCtx(() => [_createElementVNode("ul", _hoisted_9, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(bulletList.value, (bullet, index) => {
          return _openBlock(), _createElementBlock("li", {
            key: index
          }, [_createElementVNode("img", {
            src: bullet.img,
            alt: ""
          }, null, 8, _hoisted_10), _createTextVNode(" " + _toDisplayString(`贡献了${bullet.text}`), 1)]);
        }), 128))])]),
        _: 1
      })])]);
    };
  }
};