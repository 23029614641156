import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "loginWrap"
};
import loginForm from "@/components/loginForm.vue";
export default {
  __name: 'loginPage',
  setup(__props) {
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(loginForm, {
        class: "loginForm"
      }), _cache[0] || (_cache[0] = _createElementVNode("div", {
        class: "footer"
      }, [_createElementVNode("p", null, " Copyright © 2019-2024 浙江中慧网络科技有限公司 All Rights Reserved. 浙ICP备19036942号 tel:13396677978 ")], -1))]);
    };
  }
};