import "core-js/modules/es.array.push.js";
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "formBg"
};
import { reactive, ref, onMounted } from "vue";
import { getIndexData } from "../api/login";
import { useRouter } from "vue-router";
import { Lock, UserFilled } from "@element-plus/icons-vue";
import Cookies from "js-cookie";
export default {
  __name: 'loginForm',
  setup(__props) {
    const router = useRouter();
    const loginForm = reactive({
      deviceAccount: "",
      password: ""
    });
    const getCookies = () => {
      const savedUsername = Cookies.get("username");
      const savedPassword = Cookies.get("password");
      if (savedUsername && savedPassword) {
        loginForm.username = savedUsername;
        loginForm.password = savedPassword;
      }
    };
    onMounted(() => {
      getCookies();
    });
    const rememberMe = ref(false);
    const handleLogin = async () => {
      try {
        const res = await getIndexData(loginForm);
        localStorage.setItem("deviceToken", res.data.deviceToken);
        localStorage.setItem("tenantId", res.data.tenantId);
        localStorage.setItem("deviceSn", res.data.deviceSn);
        localStorage.setItem("deviceId", res.data.id);
        if (rememberMe.value) {
          Cookies.set("username", loginForm.username);
          Cookies.set("password", loginForm.password);
        } else {
          Cookies.remove("username", loginForm.username);
          Cookies.remove("password", loginForm.password);
        }
        router.push("/search");
      } catch (err) {
        // this.$message.error(err.message);
      }
    };
    return (_ctx, _cache) => {
      const _component_el_icon = _resolveComponent("el-icon");
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_checkbox = _resolveComponent("el-checkbox");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_form = _resolveComponent("el-form");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_form, {
        class: "loginForm",
        modelValue: loginForm,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => loginForm = $event)
      }, {
        default: _withCtx(() => [_cache[5] || (_cache[5] = _createElementVNode("h2", {
          class: "loginTitle"
        }, "登录", -1)), _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            class: "common_input",
            placeholder: "请输入账号",
            modelValue: loginForm.deviceAccount,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => loginForm.deviceAccount = $event)
          }, {
            prefix: _withCtx(() => [_createVNode(_component_el_icon, {
              class: "el-input__icon",
              color: "#333"
            }, {
              default: _withCtx(() => [_createVNode(_unref(UserFilled))]),
              _: 1
            })]),
            _: 1
          }, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            type: "password",
            class: "common_input",
            placeholder: "请输入密码",
            modelValue: loginForm.password,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => loginForm.password = $event),
            "show-password": ""
          }, {
            prefix: _withCtx(() => [_createVNode(_component_el_icon, {
              class: "el-input__icon",
              color: "#333"
            }, {
              default: _withCtx(() => [_createVNode(_unref(Lock))]),
              _: 1
            })]),
            _: 1
          }, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_checkbox, {
          modelValue: rememberMe.value,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => rememberMe.value = $event),
          label: "记住密码"
        }, null, 8, ["modelValue"]), _createVNode(_component_el_button, {
          type: "primary",
          class: "loginBtn",
          onClick: handleLogin
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [_createTextVNode("登录")])),
          _: 1
        })]),
        _: 1
      }, 8, ["modelValue"])]);
    };
  }
};